import React, { useState } from 'react';
import { Modal, message, Button, Spin, Upload } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { uploadLabelData } from './LabelsAction';
import fileTemplate from '../../assets/import_label_template.csv';

const ImportLabelModal = (props) => {
  const { lbGroupId } = props;
  const { formatMessage: t } = useIntl();
  const [processing, setProcessing] = useState(false);
  const [visible, setVisible] = useState(false);
  const [fileList, setFileList] = useState([]);

  const handleOk = async (event) => {
    event.stopPropagation();
    if (!fileList.length) {
      message.error('File not found.');
      return;
    }
    try {
      setProcessing(true);
      const formData = new FormData();
      formData.append('files', fileList[0]);
      await uploadLabelData(lbGroupId, formData);
      message.success('Update label group successfully!');
      setProcessing(false);
      if (props.onOk) {
        props.onOk();
      }
      handleCancel();
    } catch (error) {
      setProcessing(false);
      message.error('Update label group failed!');
    }
  };

  const handleCancel = () => {
    setFileList([]);
    setVisible(false);
  };

  const propsUpload = {
    name: 'files',
    multiple: false,
    accept: '.csv',
    beforeUpload(file) {
      const fileExt = `${file.name}`.split('.').pop();
      const isCSV = fileExt === 'csv';
      if (isCSV) {
        setFileList([file]);
      } else {
        message.error('You can only upload CSV file!');
      }

      return false;
    },
    onRemove: () => {
      setFileList([]);
    },
    fileList,
  };

  return (
    <>
      <Button
        type="primary"
        className="btn-action"
        onClick={() => setVisible(true)}
      >
        {t({ id: 'IDS_IMPORT_CSV' })}
      </Button>
      {visible && (
        <Modal
          title={t({ id: 'IDS_IMPORT' })}
          visible={visible}
          className="common-modal"
          onCancel={handleCancel}
          width={560}
          maskClosable={false}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={handleOk}
              icon={<UploadOutlined />}
            >
              {t({ id: 'IDS_UPLOAD' })}
            </Button>,
            <Button key="cancel" type="default" onClick={handleCancel}>
              {t({ id: 'IDS_CLOSE' })}
            </Button>,
          ]}
        >
          <Spin spinning={processing}>
            <div className="import-label-modal">
              <div className="import-step step-1">
                <div className="step-title">Step 1</div>
                <div className="step-content">
                  <div className="step-des">
                    <span>
                      VinDr Lab require a specific template for upload labels.
                    </span>
                    <br />
                    <span>Please take a look and follow our template.</span>
                  </div>
                  <Button type="primary">
                    <a href={fileTemplate} download="import_label_template.csv">
                      Download template
                    </a>
                  </Button>
                </div>
              </div>
              <div className="import-step step-2">
                <div className="step-title">Step 2</div>
                <div className="step-content">
                  <div className="step-des">
                    Please make sure that your file matched our template, then
                    upload your file here.
                  </div>
                  <Upload.Dragger {...propsUpload}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                  </Upload.Dragger>
                </div>
              </div>
            </div>
          </Spin>
        </Modal>
      )}{' '}
    </>
  );
};

export default React.memo(ImportLabelModal);
